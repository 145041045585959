import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Code, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Image, ImageInsert } from '@ckeditor/ckeditor5-image';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';

export class QuestionFeedbackEditor extends ClassicEditor {

    public static override builtinPlugins = [
        Alignment,
        Bold,
        Code,
        Essentials,
        Image,
        ImageInsert,
        Indent,
        Italic,
        Link,
        List,
        Paragraph,
        TextTransformation,
        Underline,
        Autoformat,
    ];

    public static override defaultConfig: EditorConfig = {

        /**
         * Toolbar configuration.
         */
        toolbar: {
            items: [
                'bold',
                'italic',
                // 'underline',
                'code',
                'link',
                '|',
                'alignment:left',
                'alignment:center',
                'alignment:right',
                '|',
                'bulletedList',
                'numberedList',
                '|',
            ]
        },

        /**
         * Alignment configuration.
         * Should apply the class `qti-align-{alignment}` to the current block.
         */
        alignment: {
            options: [
                { name: 'left', className: 'qti-align-left' },
                { name: 'center', className: 'qti-align-center' },
                { name: 'right', className: 'qti-align-right' }
            ]
        },

        htmlSupport: {
            allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },

        link: {
            decorators: {
                openInNewTab: {
                    mode: 'automatic',
                    callback: url => url ? url.startsWith('http://') || url.startsWith('https://') : false,
                    attributes: {
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    }
                }
            },
            defaultProtocol: 'http://'
        },

        language: 'en',
    };
}
